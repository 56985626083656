const config: any = {};

config.milestonesAPIBaseUrl =
  process.env.HONA_API_BASE_URL ?? 'https://api.getmilestones.com';

config.cognito = {
  REGION: process.env.AWS_COGNITO_REGION ?? 'us-east-1',
  USER_POOL_ID: process.env.AWS_USER_POOLS_ID ?? 'us-east-1_dJ8ycwI5A',
  APP_CLIENT_ID:
    process.env.AWS_USER_POOLS_WEB_CLIENT_ID ?? '1rqoub5m8cbq9u23lv25dt5hma',
  clientAccountPoolId: process.env.AWS_USER_POOLS_ID_ML
    ? JSON.parse(process.env.AWS_USER_POOLS_ID_ML)?.AWS_USER_POOLS_ID_ML
    : 'us-east-1_k0Fb5YPjy',
  appwebclientId: process.env.AWS_USER_POOLS_WEB_CLIENT_ID_ML
    ? JSON.parse(process.env.AWS_USER_POOLS_WEB_CLIENT_ID_ML)
      ?.AWS_USER_POOLS_WEB_CLIENT_ID_ML
    : '74ktv0uja516amng1pkfptm83p',
  cookieStorage: {
    domain: process.env.COOKIE_DOMAIN ?? '.hona.app',
    path: '/',
    expires: 365,
    sameSite: 'lax',
    secure: true,
  },
};
config.ignoreAuthenticationInDev = true;

config.sentry = {
  dsn_client: process.env.SENTRY_DSN_CLIENT ?? '',
};

config.clientApp = {
  baseUrl: 'http://localhost:3000',
  nextUrl: 'http://localhost:3008',
};

export const standardTheme = {
  status: {
    danger: '',
  },
  palette: {
    primary: {
      main: '#2f214b',
    },
    secondary: {
      main: '#36abbb',
      contrastText: '#ffffff',
    },
    error: {
      main: '#DF5D55',
    },
    success: {
      main: '#30a623',
      contrastText: '#ffffff',
    },
    info: {
      main: '#696969',
      contrastText: '#ffffff',
    },
  },
  typography: {
    h4: {
      fontWeight: 'bold',
      fontSize: 'larger',
    },
  },
};

export default config;
