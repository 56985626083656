import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import AuthContext from './AuthContext';
import { requestVerificationCode } from '../../api/signIn';
import config from '../../config/config';

function AuthProvider(props: { children: JSX.Element }) {
  const { children } = props;
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const isAuthenticated = useCallback(async () => {
    try {
      if (config.env === 'review') {
        await Auth.currentSession();
        return true;
      }
      window.localStorage.clear();
      window.sessionStorage.clear();
      const session = await Auth.currentSession();
      const decodedToken = session?.getIdToken().decodePayload();
      const isMagicLinkCognitoSession =
        decodedToken && decodedToken['custom:clicks'] >= 0;
      if (isMagicLinkCognitoSession) {
        await Auth.signOut();
        return false;
      }
      return true;
    } catch (error) {
      console.error(error);
      console.log('isAuthenticated error', error);
      return false;
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) return;

    isAuthenticated().then((res) => {
      setIsLoggedIn(res);
    });
  }, [isAuthenticated, isLoggedIn]);

  const signIn = useCallback(async (username: string) => {
    const result = await requestVerificationCode(username);
    return result;
  }, []);

  const answerCustomChallenge = async (username: string, answer: string) => {
    await Auth.signOut();
    const cognitoUser = await Auth.signIn(username);
    await Auth.sendCustomChallengeAnswer(cognitoUser, answer);
    const loggedIn = await isAuthenticated();
    setIsLoggedIn(loggedIn);
    return loggedIn;
  };

  const signOut = useCallback(async () => {
    await Auth.signOut();
    setIsLoggedIn(false);
  }, []);

  const contextValue = useMemo(
    () => ({
      isLoggedIn,
      isAuthenticated,
      signIn,
      answerCustomChallenge,
      signOut,
    }),
    [isLoggedIn, isAuthenticated],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export default AuthProvider;
