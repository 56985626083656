import React, { useEffect, useState } from 'react';
import './i18n/config';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from '@mui/material';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import * as Sentry from '@sentry/react';
import config from './config/config';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
import Logo from './components/NavigationBar/Logo';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/hona-sw.js')
    .then((registration) => {
      console.log('SW registered: ', registration);
    })
    .catch((registrationError) => {
      console.log('SW registration failed: ', registrationError);
    });
}

if (config.sentry.dsn_client) {
  Sentry.init({
    dsn: config.sentry.dsn_client,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: config.env,
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/localhost\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

const reloadPage = () => {
  console.log('reloading page');
  const params = new URLSearchParams(window.location.search);
  params.set('reload', 'true');
  window.location.replace(
    `${window.location.origin}${window.location.pathname}?${params.toString()}`,
  );
};

const reloadListener = () => {
  // check if cache has been invalidated on the company app
  const params = new URLSearchParams(window.location.search);
  const reload = params.get('reload');

  if (!reload) {
    reloadPage();
    return false;
  }
  return true;
};

window.addEventListener('error', reloadListener);

function FallBack() {
  const [error, setError] = useState(false);

  useEffect(() => {
    const hasError = reloadListener();
    setError(!!hasError);
  }, []);

  if (!error) return null;

  return (
    <div
      style={{
        height: '100svh',
        display: 'flex',
        flexDirection: 'column',
        gap: '2em',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <center>
        <Logo />
        <h3 style={{ margin: '2em 0 0.5em 0', color: '#2f214b' }}>
          Oops! Something went wrong
        </h3>
        <Button
          onClick={reloadPage}
          sx={{
            width: '290px',
            backgroundColor: '#2f214b',
            color: 'white',
            fontWeight: 'bold',
            fontFamily: 'Poppins, sans-serif',
            ':hover': {
              backgroundColor: '#2f214b',
            },
          }}
        >
          Refresh
        </Button>
      </center>
    </div>
  );
}

posthog.init(config.posthog.apiKey, {
  api_host: config.posthog.apiHost,
  ui_host: 'https://us.i.posthog.com',
});

root.render(
  <ErrorBoundary fallback={<FallBack />}>
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
  </ErrorBoundary>,
);

reportWebVitals();
